import React from "react";
import Sfondo from "../sfondo.jpg";
import ReactAudioPlayer from "react-audio-player";

const Main = () => {
  return (
    <div className="sfondo" style={{ backgroundImage: `url(${Sfondo})` }}>
      <ReactAudioPlayer src="canzone.mp3" autoPlay />
      <div className="container">
        <div className="rule">
          <h4>
            È arrivato, immancabile e atteso il giorno che forse più di altri
            suscita in noi piacevoli emozioni.
          </h4>
          Per molti di noi le feste natalizie rappresentano un occasione per trascorrere del tempo insieme ai nostri affetti. Inevitabilmente ci attenderanno tavole imbandite con ogni ben di
          dio. Affrontiamo questo periodo senza sensi di colpa ricordando che,
          “non si ingrassa da Natale a Capodanno, ma da Capodanno a Natale”!
          Presupposto quindi che il miglior consiglio sia già stato dato e che
          il problema non siano le feste, vediamo come poter gestire al meglio
          un periodo dove più e ripetute concessioni, se effettuate senza
          criterio, potrebbero seriamente gravare sul vostro girovita.
        </div>
        <hr />
        <div className="rule">
          <h3>REGOLA N°1</h3> <strong>Compensare: </strong> Sappiamo che il
          bilancio energetico è dato dalla differenza tra calorie entranti e
          calorie uscenti, su questa base l’organismo provvede a mantenere,
          ridurre o aumentare il proprio peso. Appare intuitivo quindi, che
          prima di ingerire un quantitativo di energia maggiore di quello che
          riusciremmo a consumare, sarebbe opportuno arrivare “scarichi” a tale
          evento. Complicato? Prendiamo come esempio un’anfora che deve essere
          riempita di acqua. <br /> È ovvio che meno sarà l’acqua iniziale
          contenuta nell’anfora, più sarà l’acqua che dovrete aggiungere per far
          sì che questa si riempia. Una volta piena, tutto l’eccesso sarà
          inutile (nel nostro corpo tale eccesso potrebbe convertirsi in
          grasso!). <br /> Far sì che il corpo arrivi “affamato” a tale evento è
          quindi un’ottima idea, come riuscirci? Ci sono molti modi, i più
          semplici sono:
          <ol>
            <li>
              <strong>
                Cercare di mangiare meno nelle 12-16 ore precedenti ai pasti più
                calorici:{" "}
              </strong>
              se ad esempio il pasto con più calorie si terrà a pranzo, è buona
              norma, la sera prima, effettuare una cena più moderata e ridurre
              poi colazione e spuntino; <br /> Se invece la concessione sarà una
              cena, è bene limitare il più possibile le quantità dei pasti
              precedenti avendone cura già dalla colazione. Anche una leggera
              riduzione delle calorie nei giorni precedenti alla licenza
              potrebbe aiutare, purché non sia né eccessiva né duratura (Es.
              Massimo 2-3 giorni prima)
            </li>
            <li>
              <strong>Effettuare un’attività sportiva prima dei pasti: </strong>
              usate a vostro vantaggio qualsiasi cosa vi faccia spendere
              energie: un po’ cyclette, qualche circuito casalingo, anche solo
              per 20-30 minuti.
              <br /> Questo creerà una condizione favorevole per immagazzinare
              al meglio il successivo surplus energetico (e poi si sa, lo sport
              fa anche bene all’umore!). <br /> Per chi può consiglio
              un’attività di 20-25 minuti ad alta intensità dove 2 minuti di
              attività blanda vengono alternati a 30 secondi di sforzo intenso
              (ad esempio un po' di jogging alternato ad uno sprint).
            </li>
            <li>
              <strong>Curare anche il dopo: </strong>Al termine dei pasti più
              abbondanti, concedete al vostro corpo una pausa, un momento per
              rilassarsi e ripulirsi. Il giorno seguente cercate di mangiare
              meno rispetto al solito e se avete esagerato con carboidrati o
              zuccheri, (Es. pasta, pane, dolci come spesso avviene) riduceteli
              drasticamente. <br />
              In media una riduzione giornaliera del 40-50% delle calorie
              assunte è ottima per il giorno seguente alla concessione.
            </li>
          </ol>
        </div>
        <div className="rule">
          <h3>REGOLA N°2</h3> <strong>Essere consapevole: </strong> Avere
          cognizione di ciò che facciamo è fondamentale, la regola del “di tutto
          un po'” rimane fondamentale. <br /> La consapevolezza riguardo al cibo
          introdotto ci aiuta a trovare il giusto equilibrio tra il piacere del
          cibo e della salute. <br />
          Ripeto, mangiate di tutto e godetevelo, ma evitate di esagerare.
          Vedrete che quei “sacrifici” temporanei saranno ricompensati. <br />{" "}
          Un altro piccolo trucco per potersi contenere al meglio è mangiare una
          buona quantità di verdura cruda prima del pasto, questo fa sì che lo
          stomaco si dilati ed invii segnali di sazietà al cervello (oltre a
          tutte le altre funzioni positive che la verdura svolge).
        </div>
        <div className="rule">
          <h3>REGOLA N°3</h3> <strong>Mai fermarsi: </strong> Le feste non sono
          una scusa per incollare i propri glutei al divano, anzi, a maggior
          ragione dovreste dedicare una parte del vostro tempo libero a qualche
          attività fisica. Questo periodo non sarà facile, ma cercate qualcosa
          che vi permetta comunque di muovervi. <br /> Incrementare il dispendio
          calorico visto l’incombente surplus alimentare, è sempre un’ottima
          cosa. <br /> Cercate di fare attività attraverso qualsiasi mezzo.
          Usate l’inventiva per creare delle attività che siano nel contempo
          salutari e divertenti. <br /> Non devono essere attività
          particolarmente impegnative. Come dice un proverbio romagnolo
          “Piuttosto che niente è meglio piuttosto”.
        </div>
        <strong>
          Con questa ultima regola vi saluto e colgo l’occasione di farvi i più
          sentiti auguri per queste feste.
          <br /> Saluti, Tommaso
        </strong>
      </div>
    </div>
  );
};

export default Main;
