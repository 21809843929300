import React, { useState } from "react";
import Lottie from "react-lottie";
import animationData from "../christmas.json";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import FlexContainer from "flexcontainer-react";

const Splash = () => {
  const [isStopped, setIsStopped] = useState(false);
  const [isPaused, setisPaused] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <FlexContainer
      type="vertical"
      alignItems="center"
      justifyContent="center"
      gap={30}
      width="100%"
    >
      <Lottie
        options={defaultOptions}
        height={350}
        width={350}
        isStopped={isStopped}
        isPaused={isPaused}
      />
      <h2 className="green">BUON NATALE!</h2>
      <Link to="/natale">
        <button>PREMI QUI PER LEGGERE I CONSIGLI</button>
      </Link>
    </FlexContainer>
  );
};

export default Splash;
