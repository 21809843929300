import logo from "./logo.svg";
import "./App.css";
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import Main from "./routes/Main";
import Splash from "./routes/Splash";
//import Placeholder from "./routes/Placeholder";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/y" exact component={Splash} />
          <Route path="/" exact component={Splash} />
          <Route path="/natale" component={Main} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
